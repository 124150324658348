<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-cusnearcontract-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານລູກຄ້າໃກ້ຈະໝົດສັນຍາ</b>
                </u>
              </h5>
              <h5>ວັນທີ : {{dateFrom}} ຫາ {{dateTo}}</h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນສັນຍາ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນລູກຄ້າ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນບໍລິການ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in cusNearCt" :key="row.cus_id">
                            <td>
                              ເລກທີ : {{row.cont_no}}
                              <br />
                              ວັນທີ : {{row.cont_date}}
                            </td>
                            <td>
                              ລະຫັດລູກຄ້າ : {{row.cus_id}}
                              <br />
                              ຊື່ລູກຄ້າ : {{row.cus_full_name}}
                              <br />
                              ທີ່ຢູ່ : {{row.cus_address}}
                              <br />
                              ເບີໂທ : {{row.cus_tel}}
                              <br />
                              ເມວ : {{row.cus_email}}
                              <br />
                              ປະເພດລູກຄ້າ : {{row.cus_group}}
                              <br />
                              ລາຍລະອຽດຂອງສັນຍາ : {{row.con_sv_remark}}
                            </td>
                            <td>
                              ບໍລິການ : {{row.service_name}}
                              <br />
                              ລາຍລະອຽດ : {{row.service_detail}}
                              <br />
                              ລາຄາ : {{row.service_price | numFormat(',000.00')}} / {{row.service_unit}}
                              <br />
                              ວັນສິນສຸດຂອງສັນຍາ : {{row.cont_end_date}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CustomerNearContract",
  props: {
    cusNearCt: {
      type: Array,
      required: true,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-cusnearcontract-view");
    },
  },
};
</script>