<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-cuswaitplayment-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານລູກຄ້າລໍຖ້າຈ່າຍເງິນ</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນບິນຮຽກເກັບ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນລູກຄ້າ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນລາຍລະອຽດການຈ່າຍ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in cusWaitPay" :key="row.cus_id">
                            <td>
                              ເລກທີ : {{row.inv_no}}
                              <br />
                              ວັນທີ : {{row.inv_date}}
                            </td>
                            <td>
                              ລະຫັດລູກຄ້າ : {{row.cus_id}}
                              <br />
                              ຊື່ລູກຄ້າ : {{row.cus_full_name}}
                              <br />
                              ທີ່ຢູ່ : {{row.cus_address}}
                              <br />
                              ເບີໂທ : {{row.cus_tel}}
                              <br />
                              ເມວ : {{row.cus_email}}
                              <br />
                              ປະເພດລູກຄ້າ : {{row.cus_group}}
                            </td>
                            <td>
                              ຈ່າຍໃຫ້ເລກທີ່ : {{row.cont_inv_no}}
                              <br />
                              ໂຄງການ : {{row.inv_project_name}}
                              <br />
                              ຈໍານວນເງິນ : {{row.inv_price_total | numFormat(',000.00')}}
                              <br />
                              ອາກອນມູນຄ່າເພີ້ມ : {{row.amount_vat | numFormat(',000.00')}}
                              <br />
                              ຍອດເງິນລວມທັງໝົດ : {{row.sum_total | numFormat(',000.00')}}
                              <br />
                              ສະກຸນເງິນ : {{row.inv_curency}}
                              <br />
                              ໝາຍເຫດ : {{row.inv_remark}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CustomerNearContract",
  props: {
    cusWaitPay: {
      type: Array,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-cuswaitplayment-view");
    },
  },
};
</script>