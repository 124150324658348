<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-cusbyid-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານຂໍ້ມູນລູກຄ້າ</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12">
                      <h5>1. ຂໍ້ມູນທົ່ວໄປ</h5>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ລະຫັດລູກຄ້າ : {{customInfo.cus_id}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ວັນທີເປັນສະມະຊິກ : {{customInfo.cus_date}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <strong>- ຊື່ ແລະ ນາມສະກຸນ : {{customInfo.cus_full_name}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <strong>- ທີ່ຢູ່ : {{customInfo.cus_address}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ເບີໂທລະສັບ : {{customInfo.cus_tel}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ເມວ : {{customInfo.cus_email}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ປະເພດລູກຄ້າ : {{customInfo.cus_group}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12">
                      <h5>2. ລາຍລະອຽດສັນຍາບໍລິການ</h5>
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ເນື້ອໃນສັນຍາ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີໝົດສັນຍາ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in customInfo.contractList" :key="row.cont_id">
                            <td class="text-center">{{row.cont_date}}</td>
                            <td>
                              ເລກທີສັນຍາ : {{row.cont_no}}
                              <br />
                              ບໍລິການ : {{row.service_name}}
                              <br />
                              ລາຄາ : {{row.service_price | numFormat(',000.00')}} / {{row.service_unit}}
                              <br />
                              ລາຍລະອຽດ : {{row.con_sv_remark }}
                            </td>
                            <td class="text-center">{{row.cont_end_date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <h5>3. ປະຫວັດການສະເໜີລາຄາ</h5>
                    </CCol>
                    <CCol sm="12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ເນື້ອໃນສະເໜີລາຄາ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຜູ້ບັນທຶກ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in customInfo.proposalList" :key="row.id">
                            <td class="text-center">{{row.pps_date}}</td>
                            <td>
                              ເລກທີສັນຍາ : {{row.pps_no}}
                              <br />
                              ຊື່ໂຄງການ : {{row.pps_project_name}}
                              <br />
                              ຈໍານວນເງິນ : {{row.pps_price_total | numFormat(',000.00')}} {{row.pps_curency}}
                              <br />
                              ອາກອນມູນຄ່າເພີ້ມ {{row.pps_vat}} % : {{row.amount_vat | numFormat(',000.00')}} {{row.pps_curency}}
                              <br />
                              ລວມເງິນ : {{row.sum_total | numFormat(',000.00')}} {{row.pps_curency}}
                            </td>
                            <td class="text-center">{{row.pps_by}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <h5>4. ປະຫວັດການຊໍາລະເງິນ</h5>
                    </CCol>
                    <CCol sm="12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ເນື້ອໃນການຊໍາລະ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ສະຖານະ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຜູ້ບັນທຶກ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in customInfo.invoiceList" :key="row.id">
                            <td class="text-center">{{row.inv_date}}</td>
                            <td>
                              ເລກທີບິນ : {{row.inv_no}}
                              <br />
                              ຊື່ໂຄງການ : {{row.pps_project_name}}
                              <br />
                              ຈໍານວນເງິນ : {{row.inv_price_total | numFormat(',000.00')}} {{row.inv_curency}}
                              <br />
                              ອາກອນມູນຄ່າເພີ້ມ {{row.inv_vat}} % : {{row.amount_vat | numFormat(',000.00')}} {{row.inv_curency}}
                              <br />
                              ລວມເງິນ : {{row.sum_total | numFormat(',000.00') }} {{row.inv_curency}}
                            </td>
                            <td class="text-center">
                              <strong v-if="row.inv_status == 1">ຊໍາລະເງິນແລ້ວ</strong>
                              <strong v-if="row.inv_status == 0">ລໍຖ້າຊໍາລະ</strong>
                            </td>
                            <td class="text-center">{{row.inv_by}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CustomerByGroup",
  props: {
    customInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-cusbyid-view");
    },
  },
};
</script>