<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-cusnearinvoice-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານລູກຄ້າໃກ້ຮອດມື້ຮຽກເກັບ</b>
                </u>
              </h5>
              <h5>ວັນທີ : {{dateFrom}} ຫາ {{dateTo}}</h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລາຍການຂອງບິນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນລູກຄ້າ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນບໍລິການ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in cusNearInv" :key="row.cus_id">
                            <td>
                              ເລກທີ : {{row.inv_no}}
                              <br />
                              ວັນທີ : {{row.inv_date}}
                            </td>
                            <td>
                              ລະຫັດລູກຄ້າ : {{row.cus_id}}
                              <br />
                              ຊື່ລູກຄ້າ : {{row.cus_full_name}}
                              <br />
                              ທີ່ຢູ່ : {{row.cus_address}}
                              <br />
                              ເບີໂທ : {{row.cus_tel}}
                              <br />
                              ເມວ : {{row.cus_email}}
                              <br />
                              ປະເພດລູກຄ້າ : {{row.cus_group}}
                              <br />
                              ໂຄງການ : {{row.inv_project_name}}
                            </td>
                            <td>
                              ບໍລິການ : {{row.service_name}}
                              <br />
                              ລາຄາ : {{row.inv_price | numFormat(',000.00')}} {{row.inv_curency}} / {{row.service_unit}}
                              <br />
                              ຈໍານວນ : {{row.inv_amount | numFormat}}
                              <br />
                              ລວມເງິນ : {{row.inv_price_total | numFormat(',000.00')}}
                              <br />
                              ອາກອນມູນຄ່າເພີ້ມ {{row.inv_vat}} % : {{row.amount_vat| numFormat(',000.00')}} {{row.inv_curency}}
                              <br />
                              ລວມເງິນທັງຫມົດ : {{row.sum_total | numFormat(',000.00') }} {{row.inv_curency}}
                              <br />
                              ຈ່າຍໃຊ້ງານແຕ່ວັນທີ : {{row.date_start}}
                              <br />
                              ແລະໝົດອາຍຸວັນທີ {{row.date_end}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CustomerNearContract",
  props: {
    cusNearInv: {
      type: Array,
      required: true,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-cusnearinvoice-view");
    },
  },
};
</script>