<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-cusbygroup-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານ{{cusGroup}}</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຊື່ ແລະ ທີ່ຢູ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຕິດຕໍ່</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in cusByGroup" :key="row.cus_id">
                            <td class="text-center">{{row.cus_id}}</td>
                            <td>{{row.cus_date}}</td>
                            <td>
                              {{row.cus_full_name}}
                              <br />
                              ທີ່ຢູ່ : {{row.cus_address}}
                            </td>
                            <td>
                              {{row.cus_tel}}
                              <br />
                              {{row.cus_email}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "CustomerByGroup",
  props: {
    cusGroup: {
      type: String,
      required: true,
    },
    cusByGroup: {
      type: Array,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-cusbygroup-view");
    },
  },
};
</script>