<template>
  <div v-if="userGroup == 'administrator'">
    <CCard>
      <CCardHeader>
        <strong>ລາຍງານລູກຄ້າ</strong>
      </CCardHeader>
      <CCardBody>
        <div style="min-height: 55vh">
          <ValidationObserver v-slot="{ invalid }">
            <CForm ref="form" :model="form" @submit.prevent="handleCUstomerReport">
              <CRow>
                <CCol sm="4"></CCol>
                <CCol sm="4">
                  <CRow>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລືອກ :</label>
                          <v-select
                            prop="select"
                            v-model="form.select"
                            :options="selectList"
                            :reduce="code => code.code"
                            label="message"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '2'">
                      <div class="form-group">
                        <label>ເລືອກປະເພດລູກຄ້າ :</label>
                        <v-select
                          prop="customerGroup"
                          v-model="form.customerGroup"
                          :options="groupList"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '3'">
                      <div class="form-group">
                        <label>ເລືອກລະຫັດລູກຄ້າ :</label>
                        <v-select
                          prop="cus_id"
                          v-model="form.cus_id"
                          :options="customer"
                          :reduce="cus_id => cus_id.cus_id"
                          label="cus_id"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '4'">
                      <div class="form-group">
                        <label>ຈາກວັນທີ :</label>
                        <date-picker
                          :config="options"
                          prop="date_from_ivn"
                          v-model="form.date_from_ivn"
                        ></date-picker>
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '4'">
                      <div class="form-group">
                        <label>ຫາວັນທີ :</label>
                        <date-picker
                          :config="options"
                          prop="date_to_ivn"
                          v-model="form.date_to_ivn"
                        ></date-picker>
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '6'">
                      <div class="form-group">
                        <label>ຈາກວັນທີ :</label>
                        <date-picker
                          :config="options"
                          prop="date_from_ct"
                          v-model="form.date_from_ct"
                        ></date-picker>
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '6'">
                      <div class="form-group">
                        <label>ຫາວັນທີ :</label>
                        <date-picker
                          :config="options"
                          prop="date_to_ct"
                          v-model="form.date_to_ct"
                        ></date-picker>
                      </div>
                    </CCol>
                    <CCol sm="12">
                      <CButton
                        type="submit"
                        :disabled="invalid || loading"
                        color="info"
                        class="float-right my-2"
                        style="width: 8rem"
                      >
                        <span v-if="!loading">
                          <CIcon name="cil-check" />ສະແດງຂໍ້ມູນ
                        </span>
                        <CSpinner
                          v-if="loading"
                          color="light"
                          style="width: 1.3rem; height: 1.3rem;"
                        />
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="4"></CCol>
              </CRow>
            </CForm>
          </ValidationObserver>
        </div>
      </CCardBody>
    </CCard>
    <CModal
      title="ລາຍງານລູກຄ້າທັງຫມົດ"
      size="lg"
      :show.sync="modalCustomerTotal"
      :closeOnBackdrop="false"
    >
      <customerTotal :cusTotal="cusTotal" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລຸກຄ້າຕາມປະເພດ"
      size="lg"
      :show.sync="modalCustomerByGroup"
      :closeOnBackdrop="false"
    >
      <customerByGroup :cusGroup="form.customerGroup" :cusByGroup="cusByGroup" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານຂໍ້ມູນລູກຄ້າ"
      size="lg"
      :show.sync="modalCustomerById"
      :closeOnBackdrop="false"
    >
      <customerById :customInfo="customInfo" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານລູກຄ້າໃກ້ຈະຮອດມື້ຮຽກເກັບ"
      size="lg"
      :show.sync="modalCustomerNearInvoice"
      :closeOnBackdrop="false"
    >
      <customerNearInvoice
        :cusNearInv="cusNearInv"
        :dateFrom="form.date_from_ivn"
        :dateTo="form.date_to_ivn"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານລູກຄ້າຄ້າງຈ່າຍ"
      size="lg"
      :show.sync="modalCustomerOutstanding"
      :closeOnBackdrop="false"
    >
      <customerOutstanding :cusOutstanding="cusOutstanding" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານລູກຄ້າໃກ້ຈະຫມົດສັນຍາ"
      size="lg"
      :show.sync="modalCustomerNearContract"
      :closeOnBackdrop="false"
    >
      <customerNearContract
        :cusNearCt="cusNearCt"
        :dateFrom="form.date_from_ct"
        :dateTo="form.date_to_ct"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານລູກຄ້າບໍ່ຕໍ່ສັນຍາ"
      size="lg"
      :show.sync="modalCustomerNotContract"
      :closeOnBackdrop="false"
    >
      <customerNotContract :cusNotCt="cusNotCt" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານລູກຄ້າລໍຖ້າຈ່າຍເງິນ"
      size="lg"
      :show.sync="modalCustomerWaitPay"
      :closeOnBackdrop="false"
    >
      <customerWaitPlayment :cusWaitPay="cusWaitPay" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getCustomerAPI,
  getCustomerByGroupAPI,
  getCustomerByIdDetailAPI,
  getCustomerNearInvAPI,
  getCustomerOutstdAPI,
  getCustomerNearCtAPI,
  getCustomerNotCtAPI,
  getCustomerWaitPayAPI,
} from "@/api/customer";
import { getApplianceGroupAPI } from "@/api/applianceGroup";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import customerTotal from "./components/CustomerTotal";
import customerByGroup from "./components/CustomerByGroup";
import customerById from "./components/CustomerById";
import customerNearInvoice from "./components/CustomerNearInvoice";
import customerOutstanding from "./components/CustomerOutstanding";
import customerNearContract from "./components/CustomerNearContract";
import customerNotContract from "./components/CustomerNotContract";
import customerWaitPlayment from "./components/CustomerWaitPlayment";
import { decrypt } from "@/libs/crypto";
export default {
  name: "CustomerReport",
  components: {
    ValidationObserver,
    ValidationProvider,
    customerTotal,
    customerByGroup,
    customerById,
    customerNearInvoice,
    customerOutstanding,
    customerNearContract,
    customerNotContract,
    customerWaitPlayment,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      form: {
        select: "",
        customerGroup: "",
        cus_id: "",
        date_from_ivn: "",
        date_to_ivn: "",
        date_from_ct: "",
        date_to_ct: "",
      },
      selectList: [
        { code: "1", message: "ລູກຄ້າທັງຫມົດ" },
        { code: "2", message: "ລຸກຄ້າແຍກຕາມປະເພດ" },
        { code: "3", message: "ລູກຄ້າຕາມລະຫັດ" },
        { code: "4", message: "ລູກຄ້າໃກ້ຈະຮອດມື້ຮຽກເກັບ" },
        { code: "8", message: "ລູກຄ້າລໍຖ້າຈ່າຍເງິນ" },
        { code: "5", message: "ລູກຄ້າຄ້າງຈ່າຍ" },
        { code: "6", message: "ລູກຄ້າໃກ້ຈະຫມົດສັນຍາ" },
        { code: "7", message: "ລູກຄ້າບໍ່ຕໍ່ສັນຍາ" },
      ],
      groupList: ["ລູກຄ້າທົ່ວໄປ", "ລູກຄ້າບໍລິສັດ", "ລູກຄ້າພິເສດ"],
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      modalCustomerTotal: false,
      modalCustomerByGroup: false,
      modalCustomerById: false,
      modalCustomerNearInvoice: false,
      modalCustomerOutstanding: false,
      modalCustomerNearContract: false,
      modalCustomerNotContract: false,
      modalCustomerWaitPay: false,
      loading: false,
      customer: [],
      cusTotal: [],
      cusByGroup: [],
      customInfo: {},
      cusNearInv: [],
      cusOutstanding: [],
      cusNearCt: [],
      cusNotCt: [],
      cusWaitPay: [],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    initialGetData() {
      getCustomerAPI().then((response) => {
        this.customer = response.data.data;
      });
    },
    handleCUstomerReport() {
      this.loading = true;

      if (this.form.select == "1") {
        getCustomerAPI()
          .then((response) => {
            this.modalCustomerTotal = true;
            this.cusTotal = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "2") {
        getCustomerByGroupAPI(this.form.customerGroup)
          .then((response) => {
            this.modalCustomerByGroup = true;
            this.cusByGroup = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "3") {
        getCustomerByIdDetailAPI(this.form.cus_id)
          .then((response) => {
            this.modalCustomerById = true;
            this.customInfo = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "4") {
        let credentials = {
          date_from: this.form.date_from_ivn,
          date_to: this.form.date_to_ivn,
        };
        getCustomerNearInvAPI(credentials)
          .then((response) => {
            this.modalCustomerNearInvoice = true;
            this.cusNearInv = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "5") {
        getCustomerOutstdAPI()
          .then((response) => {
            this.modalCustomerOutstanding = true;
            this.cusOutstanding = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "6") {
        let credentials = {
          date_from: this.form.date_from_ct,
          date_to: this.form.date_to_ct,
        };
        getCustomerNearCtAPI(credentials)
          .then((response) => {
            this.modalCustomerNearContract = true;
            this.cusNearCt = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "7") {
        getCustomerNotCtAPI()
          .then((response) => {
            this.modalCustomerNotContract = true;
            this.cusNotCt = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "8") {
        getCustomerWaitPayAPI()
          .then((response) => {
            this.modalCustomerWaitPay = true;
            this.cusWaitPay = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      }
    },
  },
};
</script>