import axios from '@/libs/api.request'

export const getApplianceGroupAPI = () => {
  return axios.request({
    url: 'appliance-group',
    method: 'get'
  })
}

export const getApplianceGroupByIdAPI = (id) => {
  return axios.request({
    url: 'appliance-group/' + id,
    method: 'get'
  })
}

export const createApplianceGroupAPI = (credentials) => {
  return axios.request({
    url: 'appliance-group',
    data: credentials,
    method: 'post'
  })
}

export const updateApplianceGroupAPI = (credentials) => {
  return axios.request({
    url: 'appliance-group/'+ credentials.id,
    data: credentials,
    method: 'put'
  })
}

export const deleteApplianceGroupAPI = (id) => {
  return axios.request({
    url: 'appliance-group/' + id,
    method: 'delete'
  })
}

